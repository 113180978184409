<template>
  <div>
    <AppCollapse
      type="border-info-2 card"
      class="mb-50"
    >
      <AppCollapseItem
        is-visible
        title
        class-header="card-header bg-blue-gradient p-0 m-50"
        class="border-info-2"
      >
        <template #header>
          <div class="d-flex-center justify-content-start gap-1">
            <b-img
              src="@icons/eticket-list.svg"
              class="icon-card"
              width="50px"
            />
            <h6 class="text-airline font-medium-3 m-0 fw-700">
              {{ $t('reservation.eticketList') }}
            </h6>
          </div>
        </template>

        <!-- ANCHOR - Số vé sau thanh toán -->
        <div v-if="hasEticket(reservationsData)">
          <strong
            v-if="hasOutEticket(reservationsData)"
            class="mb-75 font-medium-3"
          >
            <i class="text-warning">{{ $t('reservation.eticketList') }}:</i>
          </strong>

          <b-table-lite
            bordered
            centered
            responsive
            small
            thead-class="text-body text-center text-nowrap"
            tbody-class="text-body align-middle px-25"
            :fields="['index','passenger', 'eTicket', 'flight', 'ticketType', 'status', 'total']"
            :items="getTicketListByPaxLists(reservationsData)"
          >
            <template
              v-for="column in ['index', 'passenger', 'eTicket', 'flight', 'ticketType', 'status', 'total']"
              #[`head(${column})`]="data"
            >
              <span :key="column.key">
                {{ $t(`reservation.${data.column}`) }}
              </span>
            </template>

            <template #cell(index)="data">
              <span>
                {{ data.index + 1 }}
              </span>
            </template>

            <template #cell(passenger)="{ item }">
              <span class="text-nowrap">
                {{ item.passengerName }}
              </span>
            </template>
            <template #cell(eTicket)="{ item }">
              <div class="text-nowrap d-flex">
                <span
                  v-if="isHideFee || isIssueLimited || disabledViewTicketDetailNDC || isBookingOutSystem"
                  class="align-text-top"
                >
                  {{ item.number }}
                </span>
                <b-link
                  v-else
                  class="font-weight-bold d-block text-nowrap text-info"
                  @click="openViewEticketModalHandle(item)"
                >
                  <span class="align-text-top">
                    {{ item.number }}
                  </span>
                </b-link>
                <b-button
                  variant="outline-warning"
                  sm
                  class="py-25 px-50 ml-75"
                  :disabled="disabledCopy"
                  @click="handleCopyEticketNumber(item.number)"
                >
                  {{ $t('reservation.copy') }}
                </b-button>
              </div>
            </template>
            <template #cell(flight)="{ item }">
              <div
                v-if="!isEmpty(item.segments)"
                class="text-left"
              >
                <span
                  v-for="(segment, segmentIndex) in item.segments"
                  :key="segmentIndex"
                  class="text-nowrap"
                >
                  {{ (segment.departure && segment.arrival) ? `${segment.departure}-${segment.arrival}${segmentIndex !== item.segments.length -1 ? ', ': ''}` : '-' }}
                </span>
              </div>

              <div
                v-else
                class="text-left"
              >
                -
              </div>
            </template>
            <template #cell(ticketType)="{ item }">
              <div class="text-center">
                {{ item.ticketType }}
                <small
                  v-if="item.optionalServiceType"
                  class="text-airline font-italic text-truncate"
                >
                  ({{ $t(`reservation.${item.optionalServiceType.toLowerCase()}`) }})
                </small>
              </div>
            </template>
            <template #cell(status)="{ item }">
              <div class="text-center">
                {{ item.status }}
              </div>
            </template>
            <template #cell(total)="{ item }">
              <div
                v-if="['VN1A', 'VN1A_MT'].includes(reservationsData)"
                class="text-right"
              >
                <span class="fw-700">
                  {{ item.total >= 0 ? formatCurrency(item.total) : `-${formatCurrency(item.total)}` }}
                </span>
              </div>
              <div v-else>
                -
              </div>
            </template>
          </b-table-lite>

          <!-- NOTE caution for void ticket -->
          <b-alert
            variant="warning"
            :show="reservationsData.paxLists.some(pax => !!pax.eticket.find(item => item.status === 'VOIDED'))"
            class="py-50 px-2 mt-1 mb-0"
          >
            <div>{{ $t('reservation.voidTicket.caution') }}</div>
          </b-alert>
        </div>

        <!-- NOTE - Số vé ngoài -->
        <div
          v-if="hasOutEticket(reservationsData)"
          class="mt-2"
        >
          <strong class="mb-75 font-medium-3">
            <i class="text-warning">Danh sách số vé ngoài:</i>
          </strong>

          <b-table-lite
            bordered
            centered
            responsive
            tbody-class="text-dark align-middle px-25"
            :fields="['index','passenger', 'eTicket', 'flight', 'ticketType', 'status']"
            :items="reservationsData.externalTickets"
          >
            <template
              v-for="column in ['index', 'passenger', 'eTicket', 'flight', 'ticketType', 'status']"
              #[`head(${column})`]="data"
            >
              <span :key="column.key">
                {{ $t(`reservation.${data.column}`) }}
              </span>
            </template>
            <template #cell(index)="data">
              <span class="text-nowrap">
                {{ data.item.id ? data.item.id : data.index + 1 }}
              </span>
            </template>
            <template #cell(passenger)="{ item }">
              <span class="text-nowrap">
                {{ `${item.lastName} / ${item.firstName}` }}
              </span>
            </template>
            <template #cell(eTicket)="{ item }">
              <div class="text-nowrap">
                <b-link
                  class="font-weight-bold d-block text-nowrap text-info"
                  @click="openViewEticketModalHandle(item)"
                >
                  <span class="align-text-top">
                    {{ item.number }}
                  </span>
                </b-link>
              </div>
            </template>
            <template #cell(flight)="{ item }">
              <div
                v-if="!isEmpty(item.segments)"
                class="text-left"
              >
                <span
                  v-for="(segment, segmentIndex) in item.segments"
                  :key="segmentIndex"
                  class="text-nowrap"
                >
                  {{
                    (segment.departure && segment.arrival)
                      ? `${segment.departure}-${segment.arrival}${segmentIndex !== item.segments.length -1 ? ', ': ''}`
                      : '-'
                  }}
                </span>
              </div>
              <div
                v-else
                class="text-left"
              >
                -
              </div>
            </template>
            <template #cell(ticketType)="{ item }">
              {{ item.ticketType }}
            </template>
            <template #cell(status)="{ item }">
              {{ item.status }}
            </template>
          </b-table-lite>
        </div>

        <!-- ANCHOR - No ticket -->
        <div
          v-if="!hasEticket(reservationsData) && !hasOutEticket(reservationsData)"
          class="font-weight-bolder text-warning ml-50"
        >
          {{ $t('reservation.noTicketNum') }}
        </div>
      </AppCollapseItem>
    </AppCollapse>

    <!-- ANCHOR - Button -->
    <div
      v-if="!isHideFunctionButton && !hideFeature"
      class="d-flex-center justify-content-end gap-1 mb-50"
    >
      <!-- ANCHOR Thêm số vé -->
      <!-- <b-button
            v-if="reservationsData.source.includes('1S') && isEmpty(reservationsData.externalTickets)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isBookingOutSystem || !canIssueLimit"
            variant="outline-info"
            style="max-width: 340px;"
            class="px-75"
            @click="handleShowModalSearchETicket"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="font-medium-1">Thêm số vé</span>
          </b-button> -->

      <!-- ANCHOR Đổi số vé -->
      <!-- <b-button
            v-if="reservationsData.source.includes('1S') && hasEticket(reservationsData)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isBookingOutSystem || !canIssueLimit"
            variant="outline-info"
            class="px-75"
            @click="handleShowModalExchangeTicketAmount(reservationsData)"
          >
            <feather-icon
              icon="RefreshCcwIcon"
              class="mr-50"
            />
            <span class="font-medium-1">{{ $t('reservation.changeTicket') }}</span>
          </b-button> -->

      <!-- ANCHOR Hoàn vé -->
      <b-button
        v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source) && hasEticket(reservationsData) && isDevelopment"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="isBookingOutSystem || !canIssueLimit ||isLockedModify"
        variant="warning"
        size="sm"
        class="px-75d-flex-center gap-1"
        @click="handleShowModalRefundTicketVN1A(reservationsData)"
      >
        <feather-icon
          icon="CornerDownLeftIcon"
          width="20"
        />
        <span class="font-medium-1">{{ $t('reservation.refundTicket') }}</span>
      </b-button>

      <!-- ANCHOR VOID số vé -->
      <b-button
        v-if="['VN1A_MT', 'VN1A', '1S', '1S_CTRL', '1G', 'QH'].includes(reservationsData.source) && hasEticket(reservationsData)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="isBookingOutSystem || !canIssueLimit || isLockedModify || (reservationsData.status === 'CANCEL' && !reservationsData.source.includes('1S'))"
        variant="warning"
        size="sm"
        class="d-flex-center gap-1"
        @click="openVoidTicketModalHandle(reservationsData, false)"
      >
        <b-img
          src="@icons/reload.svg"
          width="16"
        />
        <span class="font-medium-1">
          {{ $t('reservation.voidTicket.title') }}
        </span>
      </b-button>

      <!-- ANCHOR Xoá số vé -->
      <!-- <b-button
            v-if="reservationsData.source.includes('1S') && hasEticket(reservationsData) && isRoleF1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isBookingOutSystem || !canIssueLimit"
            variant="outline-danger"
            class="px-75"
            @click="openDeleteTicketModalHandle"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span class="font-medium-1">Xoá số vé</span>
          </b-button> -->
      <div
        v-if="hasOutEticket(reservationsData) || (!hasOutEticket(reservationsData) && !hasEticket(reservationsData))"
        class="d-flex-center justify-content-end gap-1"
      >
        <!-- them so ve 1s -->
        <b-button
          v-if="reservationsData.source.includes('1S')"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :disabled="isBookingOutSystem || !canIssueLimit"
          variant="warning"
          class="px-75 d-flex-center gap-1"
          size="sm"
          @click="handleShowModalSearchETicket"
        >
          <b-img
            src="@icons/increase.svg"
            width="16"
          />
          <span class="font-small-4">{{ $t('reservation.addTicket') }}</span>
        </b-button>

        <!-- <b-button
            v-if="reservationsData.source.includes('1S') && hasEticket(reservationsData)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isBookingOutSystem || !canIssueLimit"
            variant="outline-info"
            class="px-75"
            @click="handleShowModalExchangeTicketAmount(reservationsData)"
          >
            <span class="font-small-4">Đổi vé VNA</span>
          </b-button>

          <b-button
            v-if="reservationsData.source.includes('1S') && hasEticket(reservationsData)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isBookingOutSystem || !canIssueLimit ||isLockedModify"
            variant="outline-info"
            class="px-1"
            @click="handleShowModalRefundTicketAmount(reservationsData)"
          >
            <span class="font-small-4">Hoàn vé</span>
          </b-button> -->

        <!-- void so ve ngoai -->
        <b-button
          v-if="['1S', '1S_CTRL'].includes(reservationsData.source) && hasOutEticket(reservationsData)"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :disabled="isBookingOutSystem || !canIssueLimit || isLockedModify"
          variant="warning"
          class="px-75 d-flex-center gap-1"
          size="sm"
          @click="openVoidTicketModalHandle(reservationsData, true)"
        >
          <b-img
            src="@icons/reload.svg"
            width="16"
          />
          <span class="font-small-4">
            {{ $t('reservation.voidTicket.titleExternal') }}
          </span>
        </b-button>

        <!-- <b-button
            v-if="reservationsData.source.includes('1S') && hasEticket(reservationsData)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="isBookingOutSystem || !canIssueLimit"
            variant="outline-danger"
            class="px-75"
            @click="openDeleteTicketModalHandle"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span class="font-small-4">Xoá số vé</span>
          </b-button> -->
      </div>
    </div>

    <ModalViewEticket
      v-if="ticketData && !isHideFunctionButton"
      :ticket-data.sync="ticketData"
      :is-booking-out-system="isBookingOutSystem"
    />

    <ModalVoidTicket
      :booking-data="reservationsData"
      :ticket-list="ticketListToVoid"
      :is-external-tickets="isExternalTickets"
    />

    <ModalDeleteTicket
      :booking-data="reservationsData"
      :ticket-list="getTicketListByPaxLists(reservationsData)"
    />

    <ModalVoidTicketCancelAlert />
    <ModalSearchEticket
      v-if="getTicketListByPaxLists(reservationsData)"
      :current-arr-ticket-length="getTicketListByPaxLists(reservationsData).length"
    />

    <ModalRefundTicket
      v-if="!isEmpty(paxsData)"
      :reservations-data="reservationsData"
      :paxs-data="paxsData"
    />

    <ModalRefundTicketVN1A
      v-if="!isEmpty(paxsData)"
      :reservations-data="reservationsData"
      :paxs-data="paxsData"
    />

    <ModalExChangeTicket
      v-if="!isEmpty(paxsData)"
      :reservations-data="reservationsData"
      :paxs-data="paxsData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BTableLite,
  BLink,
  BAlert, BImg,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { useClipboard } from '@vueuse/core'

import store from '@/store'
import { formatCurrency } from '@/@core/utils/filter'
import { env } from '@/libs/env'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

import ModalExChangeTicket from './ModalExChangeTicket.vue'
import ModalRefundTicket from './ModalRefundTicket.vue'
import ModalRefundTicketVN1A from './ModalRefundTicketVN1A.vue'
import ModalViewEticket from './ModalViewEticket.vue'

export default {
  components: {
    BButton,
    BTableLite,
    BLink,
    BAlert,
    BImg,

    ModalRefundTicket,
    ModalExChangeTicket,
    ModalViewEticket,
    ModalRefundTicketVN1A,

    ModalSearchEticket: () => import('@reservation/modals/ModalSearchEticket.vue'),
    ModalVoidTicketCancelAlert: () => import('./ModalVoidTicketCancelAlert.vue'),
    ModalVoidTicket: () => import('./ModalVoidTicket.vue'),
    ModalDeleteTicket: () => import('./ModalDeleteTicket.vue'),

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    canIssueLimit: {
      type: Boolean,
      default: false,
    },
    isLockedModify: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const ticketData = ref(null)
    const ticketListToVoid = ref([])
    const { copy } = useClipboard()
    const { isDevelopment, mamaShortName } = env
    const { hideFeature } = useReservationHandle()

    const { toastError } = useToast()

    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const meDataType = computed(() => store.getters['userStore/getMeDataType'])
    const isTypeF1CanExchangeAndRefundInternational = computed(() => ['ADM', 'OPE', 'BE'].includes(meDataType.value) && isRoleF1.value)

    const isIssueLimited = computed(() => {
      const issueLimit = agencyData.value.issueLimit.find(item => item.airlineSource === props.reservationsData.source)
      return !issueLimit?.allowance
    })

    const isHideFee = computed(() => store.getters['userStore/getHideFee'])

    function getSegment(bookingData, id) {
      const result = bookingData.itineraries
        .map(trip => trip.map(segment => segment))
        .flat()
        .filter(item => id.split(' | ').includes(item.segmentId))
      return result
    }

    function getTicketListByPaxLists(bookingData) {
      if (!['VN1A_MT', 'VN1A', '1S', '1S_CTRL', '1G', 'QH'].includes(bookingData.source)) return []
      const data = bookingData.paxLists.map(passenger => {
        const res = {
          ...passenger,
          eticket: passenger.eticket.map(item => {
            const getSegmentById = getSegment(bookingData, item.segmentId)
            return {
              ...item,
              passengerName: `${passenger.lastName} / ${passenger.firstName}`,
              passengerPaxId: passenger.paxId,
              flightSegment: getSegmentById && getSegmentById.length ? `${getSegmentById.map(seg => `${seg.departure.iataCode}-${seg.arrival.iataCode}`).join(',')}` : '',
            }
          }),
        }
        return res
      })

      const eTicketListFlatArr = data.map(passenger => passenger.eticket.map(item => item)).flat()
      const result = eTicketListFlatArr.every(item => Number(item.number))
        ? eTicketListFlatArr.sort((a, b) => Number(a.number) - Number(b.number))
        : eTicketListFlatArr

      return result
    }

    function getExternalTicket(bookingData) {
      if (!['1S', '1S_CTRL'].includes(bookingData.source)) return []

      const ticketMapped = bookingData.externalTickets.map(etk => ({
        ...etk,
        passengerName: `${etk.lastName} / ${etk.firstName}`,
        passengerPaxId: '',
        // flightSegment: !isEmpty(etk.segments) ? `${etk.segments.map(seg => `${seg.departure.iataCode}-${seg.arrival.iataCode}`).join(',')}` : '',
      }))

      const result = ticketMapped.every(item => Number(item.number))
        ? ticketMapped.sort((a, b) => Number(a.number) - Number(b.number))
        : ticketMapped

      return result
    }

    // NOTE: Những số vé HOÀN VÉ và ĐỔI VÉ: ticketType === 'TKT' và status === ['OK', 'CTRL', 'USED'] :
    function getTicketType(type) {
      // if (isDevelopment) {
      //   return ['TKT', 'EMD'].includes(type)
      // }
      return ['TKT'].includes(type)
    }

    function getStatus(type) {
      // if (isDevelopment) {
      //   return ['OK', 'USED', 'CTRL', 'OPEN', 'EMD'].includes(type)
      // }
      return ['OK', 'USED', 'CTRL', 'OPEN'].includes(type)
    }

    function resolveListTkt(paxLists) {
      const result = []
      const listPassengerHasTkt = paxLists.filter(p => !isEmpty(p.eticket)
      && p.eticket.some(ticket => getTicketType(ticket.ticketType)
      && getStatus(ticket.status)
      && (!isEmpty(ticket.segments) && !ticket.segments.some(seg => ['P', 'A', 'G'].includes(seg.bookingClass)))))

      if (!isEmpty(listPassengerHasTkt)) {
        const dataPaxMap = listPassengerHasTkt.map(p => ({
          ...p,
          eticket: p.eticket.filter(ticket => getTicketType(ticket.ticketType) && getStatus(ticket.status)).map(t => ({
            ...t,
            paxId: p.paxId,
            firstName: p.firstName,
            lastName: p.lastName,
            paxType: p.paxType,
          })),
        }))

        dataPaxMap.forEach(passenger => {
          passenger.eticket.forEach(eticket => {
            result.push(eticket)
          })
        })
      }

      return result
    }

    const paxsData = ref([])

    async function handleShowModalRefundTicketVN1A({ isDomestic, paxLists }) {
      if (!isDomestic) {
        toastError({
          title: 'messagesList.error',
          content: 'Hiện tại chỉ hỗ trợ hoàn vé nội địa!',
        })
        return
      }

      const passengerArray = resolveListTkt(paxLists)

      if (isEmpty(passengerArray)) {
        toastError({
          title: 'messagesList.error',
          content: 'Không tìm thấy số vé có thể hoàn, vui lòng kiểm tra lại!',
        })
      } else {
        await new Promise(resolve => {
          paxsData.value = passengerArray
          resolve()
        })

        this.$bvModal.show('modal-modify-eticket-refund-ticket-vn1a')
      }
    }

    async function handleShowModalRefundTicketAmount({ isDomestic, paxLists }) {
      if (!isDomestic && !isTypeF1CanExchangeAndRefundInternational.value) {
        toastError({
          title: 'messagesList.error',
          content: `Chỉ ADM, OPE, Booker của ${mamaShortName} có quyền hoàn vé quốc tế!`,
        })
        return
      }

      const passengerArray = resolveListTkt(paxLists)

      if (isEmpty(passengerArray)) {
        toastError({
          title: 'messagesList.error',
          content: 'Không tìm thấy số vé có thể hoàn, vui lòng kiểm tra lại!',
        })
      } else {
        await new Promise(resolve => {
          paxsData.value = passengerArray

          resolve()
        })

        this.$bvModal.show('modal-modify-eticket-refund-ticket')
      }
    }

    async function handleShowModalExchangeTicketAmount({ isDomestic, paxLists }) {
      if (!isDomestic && !isTypeF1CanExchangeAndRefundInternational.value) {
        toastError({
          title: 'messagesList.error',
          content: `Chỉ ADM, OPE, Booker của ${mamaShortName} có quyền đổi vé quốc tế!`,
        })
        return
      }

      const passengerArray = resolveListTkt(paxLists)

      if (isEmpty(passengerArray)) {
        toastError({
          title: 'messagesList.error',
          content: 'Không tìm thấy số vé có thể xuất đổi, vui lòng kiểm tra lại!',
        })
      } else {
        await new Promise(resolve => {
          paxsData.value = passengerArray

          resolve()
        })

        this.$bvModal.show('modal-modify-eticket-exchange-ticket')
      }
    }

    async function openViewEticketModalHandle(item) {
      await new Promise(resolve => {
        ticketData.value = item
        resolve()
      })
      this.$bvModal.show('modal-modify-view-eticket-details')
    }

    // Check eticket cùng ngày không được VOID - (QH)
    function checkFlightDateDifferentFromPaymentDate(flightDate, paymentDate) {
      const flightDateObj = new Date(flightDate)
      const paymentDateObj = new Date(paymentDate)

      const flightDateOnly = new Date(flightDateObj.getFullYear(), flightDateObj.getMonth(), flightDateObj.getDate())
      const paymentDateOnly = new Date(paymentDateObj.getFullYear(), paymentDateObj.getMonth(), paymentDateObj.getDate())

      return flightDateOnly.getTime() !== paymentDateOnly.getTime()
    }

    const isExternalTickets = ref(false)

    // async function openVoidTicketModalHandle(data, isExTk) {
    //   ticketListToVoid.value = []
    //   isExternalTickets.value = isExTk

    //   if (isExTk) {
    //     ticketListToVoid.value = await getExternalTicket(data)
    //     this.$bvModal.show('modal-void-ticket')
    //     return
    //   }

    //   if (['QH'].includes(data.source)) {
    //     const { paxLists, flightDate } = data
    //     const timePayment = paxLists[0].eticket[0].dateIss || null
    //     if (!timePayment) {
    //       toastError({
    //         title: 'messagesList.error',
    //         content: 'Lỗi lấy thông tin thanh toán số vé, vui lòng kiểm tra lại!',
    //       })
    //       return
    //     }

    //     const isDifferentTime = checkFlightDateDifferentFromPaymentDate(flightDate, timePayment)

    //     if (!isDifferentTime) {
    //       toastError({
    //         title: 'messagesList.error',
    //         content: 'Vé VOID chỉ áp dụng khi ngày xuất KHÁC ngày bay!',
    //       })
    //       return
    //     }

    //     ticketListToVoid.value = await getTicketListByPaxLists(data)
    //     this.$bvModal.show('modal-void-ticket')
    //   }

    //   ticketListToVoid.value = await getTicketListByPaxLists(data)
    //   this.$bvModal.show('modal-void-ticket')
    // }

    async function openVoidTicketModalHandle(data, isExTk) {
      ticketListToVoid.value = []
      isExternalTickets.value = isExTk

      if (isExTk) {
        ticketListToVoid.value = await getExternalTicket(data)
      } else if (['QH'].includes(data.source)) {
        const { paxLists, flightDate } = data
        const timePayment = paxLists[0].eticket[0].dateIss || null

        if (!timePayment) {
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi lấy thông tin thanh toán số vé, vui lòng kiểm tra lại!',
          })
          return
        }

        const isDifferentTime = checkFlightDateDifferentFromPaymentDate(flightDate, timePayment)

        if (!isDifferentTime) {
          toastError({
            title: 'messagesList.error',
            content: 'Vé VOID chỉ áp dụng khi ngày xuất KHÁC ngày bay!',
          })
          return
        }

        ticketListToVoid.value = await getTicketListByPaxLists(data)
      } else {
        ticketListToVoid.value = await getTicketListByPaxLists(data)
      }

      this.$bvModal.show('modal-void-ticket')
    }

    function openDeleteTicketModalHandle() {
      this.$bvModal.show('modal-modify-delete-eticket')
    }

    // Check số vé của mã đặt chỗ
    const hasEticket = bookingData => bookingData.paxLists.filter(item => item.eticket.length > 0).length > 0

    // Check số vé NGOÀI của mã đặt chỗ
    const hasOutEticket = bookingData => !isEmpty(bookingData.externalTickets)

    function handleShowModalSearchETicket() {
      this.$bvModal.show('reservation-search-by-eticket-modal')
    }

    const disabledCopy = ref(false)

    function handleCopyEticketNumber(number) {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(number)
        .then(() => {
          this.$root.toastSuccess('reservation.copyEticketSuccess')
        })
        .catch(error => {
          this.$root.toastError('reservation.copyEticketError')
          console.error(this.$t('reservation.copyEticketError'), error)
        })
    }

    const disabledViewTicketDetailNDC = computed(() => props.reservationsData.source === '1G' && props.reservationsData.extra.some(item => item?.contentSource === 'NDC'))

    return {
      handleShowModalSearchETicket,
      getTicketListByPaxLists,
      openViewEticketModalHandle,
      openVoidTicketModalHandle,
      openDeleteTicketModalHandle,
      handleShowModalRefundTicketVN1A,
      handleShowModalRefundTicketAmount,
      handleShowModalExchangeTicketAmount,
      getExternalTicket,
      isExternalTickets,
      ticketListToVoid,
      ticketData,
      paxsData,
      formatCurrency,

      isEmpty,
      isIssueLimited,
      isHideFee,
      hasEticket,
      hasOutEticket,
      isRoleF1,

      disabledCopy,
      handleCopyEticketNumber,
      disabledViewTicketDetailNDC,
      hideFeature,

      isDevelopment,
    }
  },
}
</script>
